import React, { FC, useEffect, useState } from 'react'

import { PhoneIcon, EnvelopeIcon, ShareIcon } from '@heroicons/react/24/outline'
import {
  Dialog,
  DialogActions,
  DialogContent,
  Menu,
  MenuItem,
  Popover,
  Typography,
} from '@mui/material'
import { captureException } from '@sentry/browser'
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state'
import {
  directoryUserQuery,
  UpdateUserPersonalInfo,
  UpdateUserPersonalInfoVariables,
} from 'types/graphql'
import { useMediaQuery } from 'usehooks-ts'

import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import useAnalytics from 'src/lib/hooks/useAnalytics'
import { useAuth } from 'src/Providers'

import EditUserProfileForm from '../EditUserProfileForm/EditUserProfileForm'
import { QUERY } from '../InformerDirectoryUserCell'
import Button from '../Library/Button/Button'
import UserAvatar from '../UserAvatar/UserAvatar'
interface InformerDirectoryUserProps {
  membershipData: directoryUserQuery['directoryMember']
  updateSelectedMembership: React.Dispatch<React.SetStateAction<number>>
}

const InformerDirectoryUser: FC<InformerDirectoryUserProps> = ({
  membershipData,
}) => {
  const { currentUser, hasRole } = useAuth()
  const { trackEvent } = useAnalytics()
  const isMobile = useMediaQuery('(max-width: 640px)')
  const isAdmin = hasRole(['ADMIN', 'OWNER'])
  const userId = currentUser.userData.id
  const isCurrentUserOrAdmin = userId === membershipData?.user.id || isAdmin
  const [saving, setSaving] = useState(false)

  // popover
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)
  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handlePopoverClose = () => {
    setAnchorEl(null)
  }
  const open = Boolean(anchorEl)

  const { id: membershipId, user, membershipGroups } = membershipData

  const [isModalOpen, setModalOpen] = useState(false)

  const openModal = () => {
    setModalOpen(true)
  }

  const closeModal = () => {
    setModalOpen(false)
  }

  const isSupportMode =
    currentUser.isClientAlias && currentUser.userData.role !== 'SUPERADMIN'

  const groups = membershipGroups
    ?.map((group) => group?.membershipGroup.name)
    .join(', ')

  const bgImage = currentUser.parentData.headerUrl

  const [name, setName] = useState(user.name)
  const [phone, setPhone] = useState(user.phone)

  const [avatarStorageObject, setAvatarStorageObject] = useState(
    user.avatarStorageObject,
  )

  useEffect(() => {
    setName(user.name)
    setPhone(user.phone)
    setAvatarStorageObject(user.avatarStorageObject)
  }, [membershipData])

  const UPDATE_USER_PERSONAL_INFO = gql`
    mutation UpdateUserPersonalInfo(
      $input: UpdateUserPersonalInfoInput!
      $id: Int!
    ) {
      updateUserPersonalInfo(input: $input, id: $id) {
        id
      }
    }
  `

  const [updateUserProfileMutation] = useMutation<
    UpdateUserPersonalInfo,
    UpdateUserPersonalInfoVariables
  >(UPDATE_USER_PERSONAL_INFO, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: QUERY,
        variables: {
          searchId: membershipId,
        },
      },
    ],
  })

  const handleFormSubmit = async (event) => {
    if (event) {
      event.preventDefault()
    }

    setSaving(true)

    const nameTrimmed = (name || '').trim()

    try {
      await updateUserProfileMutation({
        variables: {
          id: user.id,
          input: {
            name: nameTrimmed,
            phone: phone,
            ...(avatarStorageObject && {
              avatarStorageObjectId: avatarStorageObject.id,
            }),
          },
        },
      })
      setSaving(false)
      closeModal()
      toast.success('Profile updated successfully')
    } catch (error) {
      captureException(error, {
        extra: {
          message: `updateUserProfileMutation: Error while updating user with ID: ${user.id}`,
        },
      })
      setSaving(false)
      toast.error(error.message)
      closeModal()
    }
  }

  const handleCopyToClipboard = (text) => {
    toast.success('Copied to clipboard')
    navigator.clipboard.writeText(text)
  }

  return (
    <div
      className="relative grid h-36 bg-gradient-to-r from-sky-500 to-indigo-500 bg-cover bg-center bg-no-repeat md:h-[300px] lg:m-5 lg:rounded-2xl"
      style={bgImage ? { backgroundImage: `url(${bgImage})` } : {}}
    >
      <div className="absolute top-28 flex w-full justify-center lg:top-40">
        <UserAvatar
          src={user.avatarUrl}
          className="absolute -top-14 left-1/2 z-10 h-36 w-36 -translate-x-1/2 transform bg-white shadow-xl lg:h-60 lg:w-60"
        />

        <Dialog
          open={isModalOpen}
          onClose={closeModal}
          scroll="paper"
          fullWidth={true}
          sx={{
            '& .MuiDialog-container': {
              '& .MuiPaper-root': {
                width: '100%',
                maxWidth: '800px',
              },
            },
          }}
        >
          <DialogContent>
            <EditUserProfileForm
              user={user}
              handleFormSubmit={handleFormSubmit}
              setAvatarStorageObject={setAvatarStorageObject}
              name={name}
              setName={setName}
              phone={phone}
              setPhone={setPhone}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                closeModal()
                trackEvent('People', 'edit profile cancel')
              }}
              disabled={saving}
              variant="outlined"
              fullWidth={false}
            >
              Cancel
            </Button>
            <div
              title={
                isSupportMode
                  ? 'In Support mode, only Super Admins are allowed to update members'
                  : ''
              }
            >
              <Button
                variant="contained"
                loading={saving}
                disabled={isSupportMode}
                loadingPosition="center"
                type="submit"
                onClick={(e) => {
                  handleFormSubmit(e)
                  trackEvent('People', 'edit profile save', {
                    eventCategory: 'People',
                  })
                }}
              >
                Save
              </Button>
            </div>
          </DialogActions>
        </Dialog>

        <div className="relative mb-6 w-full max-w-[90%] rounded-lg bg-white p-6 shadow-[0_10px_20px_rgb(0,0,0,0.2)] lg:mb-8 lg:p-12 2xl:max-w-[70%]">
          {!isMobile && isCurrentUserOrAdmin && (
            <Button
              onClick={() => {
                openModal()
                trackEvent('People', 'edit profile')
              }}
              fullWidth={false}
              variant="outlined"
              className="absolute right-5 top-5"
              size="small"
              buttonDataTestId="edit-profile-button"
            >
              Edit Profile
            </Button>
          )}
          <div className="flex flex-col items-center justify-center pt-[90px] lg:pt-[180px]">
            <div
              data-testid="informer-profile-name"
              className="text-center text-2xl font-semibold text-gray-600 sm:text-3xl lg:text-4xl"
            >
              {user.name}
            </div>
            <div className="pt-2 text-center text-sm font-bold text-gray-400 lg:text-lg">
              {user.position}
            </div>
            <div className="pt-4 text-sm text-gray-400">
              <div className="flex flex-col gap-1">
                {user.phone && (
                  <Button
                    variant="text"
                    className="py-1"
                    startIcon={<PhoneIcon className="h-4 w-4" />}
                    onClick={(e) => {
                      e.preventDefault()
                      window.location.href = `tel:${user.phone}`
                      trackEvent('People', 'click call person button', {
                        selectedUser: user.name,
                      })
                    }}
                    buttonDataTestId="call-person-button"
                  >
                    {user.phone}
                  </Button>
                )}

                <Button
                  variant="text"
                  className="py-1"
                  startIcon={<EnvelopeIcon className="h-4 w-4" />}
                  onClick={(e) => {
                    e.preventDefault()
                    window.location.href = `mailto:${user.email}`
                    trackEvent('People', 'click email person button', {
                      selectedUser: user.name,
                    })
                  }}
                  buttonDataTestId="email-person-button"
                >
                  <Typography textTransform={'lowercase'}>
                    {user.email}
                  </Typography>
                </Button>
                <PopupState variant="popover" popupId="share-popup-menu">
                  {(popupState) => (
                    <>
                      <Button
                        variant="text"
                        className="py-1"
                        startIcon={<ShareIcon className="h-4 w-4" />}
                        {...bindTrigger(popupState)}
                      >
                        Share Contact Details
                      </Button>
                      <Menu {...bindMenu(popupState)}>
                        {user.phone && (
                          <MenuItem
                            onClick={() => {
                              handleCopyToClipboard(user.phone.toString())
                              trackEvent('People', 'copy phone number', {
                                selectedUser: user.name,
                              })
                              popupState.close()
                            }}
                          >
                            <PhoneIcon
                              className="-ml-1 mr-2 h-5 w-5"
                              aria-hidden="true"
                            />
                            Copy Phone
                          </MenuItem>
                        )}
                        <MenuItem
                          onClick={() => {
                            handleCopyToClipboard(user.email.toString())
                            trackEvent('People', 'copy email', {
                              selectedUser: user.name,
                            })
                            popupState.close()
                          }}
                        >
                          <EnvelopeIcon
                            className="-ml-1 mr-2 h-5 w-5"
                            aria-hidden="true"
                          />
                          Copy Email
                        </MenuItem>
                      </Menu>
                    </>
                  )}
                </PopupState>
              </div>
            </div>
            <Typography
              aria-owns={open ? 'mouse-over-popover' : undefined}
              aria-haspopup="true"
              onMouseEnter={handlePopoverOpen}
              onMouseLeave={handlePopoverClose}
              className="lg:text-md pt-4 text-sm font-semibold text-gray-400 underline hover:cursor-pointer"
            >
              {membershipGroups.length}{' '}
              {membershipGroups.length > 1 ? 'Groups' : 'Group'}
            </Typography>

            <Popover
              id="mouse-over-popover"
              sx={{
                pointerEvents: 'none',
              }}
              open={open}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              onClose={handlePopoverClose}
              disableRestoreFocus
            >
              <Typography
                sx={{ p: 1 }}
                className="text-sm font-normal text-gray-400"
              >
                {groups}
              </Typography>
            </Popover>
          </div>
        </div>
      </div>
    </div>
  )
}

export default InformerDirectoryUser
