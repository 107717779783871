import React, { FC } from 'react'

import { directoryUserQuery, directoryUserQueryVariables } from 'types/graphql'

import { CellFailureProps, CellSuccessProps } from '@redwoodjs/web'

import InformerDirectoryUser from 'src/components/InformerDirectoryUser'
import { default as LoadingSpinner } from 'src/components/Library/Loading'
import { AddCylinder } from 'src/lib/svg/SvgComponents'

export const beforeQuery = (props) => {
  return {
    variables: props,
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  }
}

export const QUERY = gql`
  query directoryUserQuery($searchId: Int!) {
    directoryMember: membership(id: $searchId) {
      id
      clientId
      role
      membershipType
      division
      department
      team
      position
      user {
        id
        avatarUrl
        avatarStorageObject {
          id
          downloadUrl
        }
        email
        name
        phone
        position
      }
      reportsTo {
        id
        user {
          id
          name
          avatarUrl
          position
        }
      }
      membershipGroups {
        membershipGroup {
          id
          name
        }
      }
    }
  }
`

export type DirectoryMemberMembershipGroup =
  directoryUserQuery['directoryMember']['membershipGroups'][0]['membershipGroup']
export const Loading = () => (
  <div
    className="flex items-center justify-center"
    style={{ minHeight: 'calc(100vh - 80px)' }}
  >
    <LoadingSpinner />
  </div>
)

export const Empty = () => {
  return (
    <div className="flex h-[calc(100vh-64px)]">
      <div className="m-16 grid grow place-items-center rounded-xl border-2 border-dashed border-gray-300 p-1">
        <div className="text-center">
          <AddCylinder />
          <span className="mt-2 block text-sm font-medium text-gray-900">
            Select a user
          </span>
        </div>
      </div>
    </div>
  )
}
export const EmptyInformerDirectoryUser = Empty

export const Failure = ({ error }: CellFailureProps) => (
  <div className="rw-cell-error">{error.message}</div>
)

type SuccessProps = CellSuccessProps<
  directoryUserQuery,
  directoryUserQueryVariables
> & {
  updateSelectedMembership: React.Dispatch<React.SetStateAction<number>>
}

export const Success: FC<SuccessProps> = ({
  directoryMember,
  updateSelectedMembership,
}) => {
  return (
    <InformerDirectoryUser
      membershipData={directoryMember}
      updateSelectedMembership={updateSelectedMembership}
    />
  )
}
